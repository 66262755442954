<template>
  <div class="content-container">
    <PageTitle :title="$t('TEACHER_OVERVIEW.TEACHERS')" class="page-title" />

    <h1 class="page-section-title section-title">
      {{ $t('TEACHER_OVERVIEW.TEACHER_DETAILS.DETAILS') }}
    </h1>
    <BackButton
      :text="$t('BACK_OVERVIEW')"
      :route-name="ROUTE_NAMES_HELPDESK.TEACHERS_OVERVIEW"
    />

    <!-- School detail fields -->
    <div class="teacher-details-box">
      <UserDataField
        v-if="teacherDetails"
        :field-title="$t('NAME')"
        :user-data="teacherDetails.name"
        class="data-item"
      />
      <UserDataField
        v-if="teacherDetails"
        :field-title="$t('SCHOOL')"
        :user-data="teacherDetails.schoolName"
        class="data-item"
      >
        <template #right-content >
          <div class="approval-info-wrapper">
            <div v-if="teacherDetails.isApproved"
                 class="option approved">
              Goedgekeurd
            </div>
            <div v-if="!teacherDetails.isApproved"
                 class="option decline"
                 @click="preDecline">
              <inline-svg :src="require('../../../assets/icons/icn_reject.svg')"/>
              Afwijzen
            </div>
            <div v-if="!teacherDetails.isApproved"
                 class="option approve"
                 @click="preApprove">
              <inline-svg :src="require('../../../assets/icons/icn_accept.svg')"/>
              Goedkeuren
            </div>

          </div>
        </template>
      </UserDataField>
      <div class="teacher-details-email-settings">
        <UserDataField
          v-if="teacherDetails"
          :field-title="$t('EMAIL')"
          :user-data="teacherDetails.email"
          class="data-item left"
        />
        <UserDataField
          v-if="teacherDetails"
          :field-title="
            $t('TEACHER_OVERVIEW.TEACHER_DETAILS.NEWSLETTER_STATUS')
          "
          :user-data="relevantNewsLetterTranslationKey"
          :status-badge="{
            color: badgeColor
          }"
          class="data-item right"
        />
      </div>
      <div class="edit-details">
        <BasicButton :button-text="$t('EDIT_DATA')" @click="editTeacher"/>
      </div>
    </div>

    <!-- Linked Classes -->
    <h1 class="page-section-title section-title">
      {{ $t('SCHOOL_DETAILS.LINKED_CLASSES') }}
    </h1>
    <CustomTable
      v-if="canRenderLinkedClassesTable"
      :options="linkedClassesOptions"
      :table-data="teacherDetails.teacherLinkedClasses"
      table-class="schools-table linked-classes-teacher-details"
      class="linked-table"
    >
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton
            :text="$t('CLASSES_HELPDESK.UNLINK')"
            :icon="require('../../../assets/icons/ic_remove_person.svg')"
            custom-class="button-red left"
            @click="unlinkClass(props.rowData)"
          />
          <ActionButton
            :text="$t('VIEW')"
            :icon="require('../../../assets/icons/icn_visibility.svg')"
            custom-class="button-blue right"
            @click="viewClassDetails(props.rowData)"
          />
        </div>
      </template>
    </CustomTable>

    <!-- Other Classes -->
    <h1 class="page-section-title section-title">
      {{ $t('TEACHER_OVERVIEW.TEACHER_DETAILS.OTHER_CLASSES') }}
    </h1>
    <CustomTable
      v-if="canRenderOtherClassesTable"
      :options="otherClassesOptions"
      :table-data="teacherDetails.otherSchoolClasses"
      table-class="schools-table other-classes-teacher-details"
      class="linked-table"
    >
      <template #name="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton
            v-if="teacherDetails.isApproved"
            :text="$t('CLASSES_HELPDESK.LINK')"
            :icon="require('../../../assets/icons/ic_add_person.svg')"
            custom-class="button-green left"
            @click="linkClass(props.rowData)"
          />
          <ActionButton
            :text="$t('VIEW')"
            :icon="require('../../../assets/icons/icn_visibility.svg')"
            custom-class="button-blue right"
            @click="viewClassDetails(props.rowData)"
          />
        </div>
      </template>
    </CustomTable>
    <p v-if="!canRenderOtherClassesTable" class="page-section-text section-text">
      {{ $t('TEACHER_OVERVIEW.TEACHER_DETAILS.NO_OTHER_CLASSES') }}
    </p>

    <BasicModal v-if="isBasicVersionDisplayed" />

  </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg'
import PageTitle from '@/components/elements/texts/PageTitle'
import BackButton from '@/components/elements/BackButton'
import UserDataField from '@/components/elements/settings/UserDataField'
import CustomTable from '@/components/table/CustomTable'
import ActionButton from '@/components/table/ActionButton'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import {
  getTableOptions,
  OTHER_CLASSES
} from '@/utils/helpers/customTable/CustomTableHelper'
import { computed, ref } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import ModalController, { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import BasicModal from '@/components/elements/basicModal/BasicModal'


import {
  GET_TEACHER_DETAILS,
  LINK_TEACHER_TO_CLASS,
  UNLINK_TEACHER_TO_CLASS
} from '@/store/modules/helpdesk/actions'

import nl from '@/utils/language/nl.json'
import ApiHandler from '../../../utils/handlers/ApiHandler'
import BasicButton from '@/components/elements/basic/BasicButton'

export default {
  name: 'TeacherDetails',
  components: {
    PageTitle,
    BackButton,
    UserDataField,
    CustomTable,
    ActionButton,
    InlineSvg,
    BasicModal,
    BasicButton,
  },
  setup() {
    const state = {
      positiveBadgeColor: '#85FF87',
      negativeBadgeColor: '#FF8585'
    }
    const route = useRoute()
    const router = useRouter()
    const teacherId = route.params.teacherId
    const store = useStore()

    const modalController = ref(new ModalController())
    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.APPROVE_TEACHER ||
      activeModal.value === MODAL_NAMES.DECLINE_TEACHER
    )

    function reloadTeacherDetails() {
      store.dispatch(GET_TEACHER_DETAILS, teacherId)
    }

    function editTeacher() {
      router.push({ name: ROUTE_NAMES_HELPDESK.TEACHER_EDIT, params: { teacherId: teacherId } })
    }

    reloadTeacherDetails()

    const teacherDetails = computed(() => {
      return store.getters.getTeacherDetails
    })


    const relevantNewsLetterTranslationKey = computed(() => {
      return teacherDetails.value.receiveGeneralNewsletter
        ? nl.TEACHER_OVERVIEW.TEACHER_DETAILS.SIGNED_UP
        : nl.TEACHER_OVERVIEW.TEACHER_DETAILS.NOT_SIGNED_UP
    })

    const canRenderLinkedClassesTable = computed(() => {
      return (
        teacherDetails.value !== undefined &&
        teacherDetails.value.teacherLinkedClasses.length > 0
      )
    })
    const canRenderOtherClassesTable = computed(() => {
      return (
        teacherDetails.value !== undefined &&
        teacherDetails.value.otherSchoolClasses.length > 0
      )
    })

    const badgeColor = computed(() =>
      teacherDetails.value.receiveGeneralNewsletter
        ? state.positiveBadgeColor
        : state.negativeBadgeColor
    )

    /** Table Actions **/
    function viewClassDetails(clickedClass) {
      router.push({
        name: ROUTE_NAMES_HELPDESK.CLASS_DETAILS,
        params: { classId: clickedClass.id }
      })
    }

    function linkClass(clickedClass) {
      store.dispatch(LINK_TEACHER_TO_CLASS, {
        teacherId: teacherId,
        classId: clickedClass.id
      }).then(() => {
        reloadTeacherDetails()
      })
    }

    function unlinkClass(clickedClass) {
      store.dispatch(UNLINK_TEACHER_TO_CLASS, {
        teacherId: teacherId,
        classId: clickedClass.id
      }).then(() => {
        reloadTeacherDetails()
      })
    }

    function preApprove() {
      modalController.value.setModal(BASIC_MODAL_CONTENT.APPROVE_TEACHER(teacherDetails.value.schoolName), () => approveOrDecline(true), null, null, MODAL_NAMES.APPROVE_TEACHER)
    }


    function approveOrDecline(approve) {
      const formData = new FormData()
      formData.append('teacherId', teacherId)
      ApiHandler.post(`helpdesk/teacher/${(approve ? 'approve' : 'decline')}-teacher`, formData).then(response => {
        if (approve) {
          reloadTeacherDetails()
        } else {
          router.push({
            name: ROUTE_NAMES_HELPDESK.TEACHERS_OVERVIEW
          })
        }
        modalController.value.resetActiveModal()
      })
    }

    function preDecline() {
      modalController.value.setModal(BASIC_MODAL_CONTENT.DECLINE_TEACHER(teacherDetails.value.schoolName), () => approveOrDecline(false), null, null, MODAL_NAMES.APPROVE_TEACHER)
    }

    return {
      ROUTE_NAMES_HELPDESK,
      // Using the OTHER_CLASSES option since this has the layout we need.
      // Another page also uses LINKED_CLASSES and this has different options.
      linkedClassesOptions: getTableOptions(OTHER_CLASSES),
      otherClassesOptions: getTableOptions(OTHER_CLASSES),
      viewClassDetails,
      linkClass,
      unlinkClass,
      state,
      relevantNewsLetterTranslationKey,
      teacherId,
      store,
      teacherDetails,
      badgeColor,
      reloadTeacherDetails,
      canRenderLinkedClassesTable,
      canRenderOtherClassesTable,
      preApprove,
      preDecline,
      isBasicVersionDisplayed,
      editTeacher,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/base.variables';
@import '~@/assets/css/base.mixins';

.teacher-details-box {
  margin-top: rem(24);
  width: rem(746);
  .data-item {
    margin-bottom: rem(16);
  }

  .edit-details {
    margin-bottom: rem(56);
    margin-top: rem(40);
  }

  .teacher-details-email-settings {
    display: flex;
    justify-content: space-between;

    .left {
      width: 63%;
    }
    .right {
      width: 35%;
    }
  }
}

.linked-table {
  margin-bottom: rem(32);
}

.action-button-group {
  display: flex;
  justify-content: flex-end;
  gap: rem(10);
  .left {
    margin-bottom: 0;
  }

  .right {
    margin-bottom: 0;
  }

  .base-button {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.approval-info-wrapper{
  text-align: right;
  margin-left: auto;
  display: flex;
  gap: rem(10);

  svg {
    margin-right: rem(5);
  }

  :deep g {
    fill: white;
  }
}

.option{
  padding: rem(10) rem(15);
  border-radius: rem(10);
  color: white;
}

.approved{
 background-color: #E5F7F6;
 color: #9ab5b3;
}


.approve{
  background-color: #66CBA6;
  cursor: pointer;
}

.decline{
 background-color: #F35757;
 cursor: pointer;
}
</style>
